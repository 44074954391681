import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// Define proper types
interface TeamMember {
  Image: string;
  Headline: string;
  Description: string;
}

interface TeamData {
  dynamicarrowType: { value: 'Arrow1' | 'Arrow2' };
  dynamicautoPlay: { value: 'On' | 'Off' };
  dynamicinfiniteLoop: { value: 'On' | 'Off' };
  dynamicshowArrow: { value: 'On' | 'Off' };
  dynamicshowIndicators: { value: 'On' | 'Off' };
  dynamiccarouselStatus: { value: 'On' | 'Off' };
  scrolllogos_count_display?: { value: number };
  bordertype_border_radius?: { value: number };
  Headline_final_class?: { value: string };
  Description_final_class?: { value: string };
  dynamic: { value: TeamMember[] };
}

interface ArrowProps {
  arrowType: 'Arrow1' | 'Arrow2';
}

// Separate arrow components into their own files in practice
const PrevBtn = ({ arrowType }: ArrowProps) => (
  <div
    className='h-[35px] w-[35px] md:h-[50px] md:w-[50px] absolute top-1/2 transform -translate-y-1/2 left-[0px] z-10 cursor-pointer leftArrow'
    style={{ zIndex: '39' }}
  >
    {arrowType === 'Arrow1' ? (
      <button
        name='Previous'
        className='bg-light-gray bg-opacity-90 flex justify-center items-center w-10 h-10 rounded-md shadow-md focus:outline-none'
      >
        <svg
          viewBox='0 0 20 20'
          fill='currentColor'
          className='chevron-left w-10 h-10'
        >
          <path
            fillRule='evenodd'
            d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
            clipRule='evenodd'
          ></path>
        </svg>
      </button>
    ) : (
      <button
        name='Previous'
        className='bg-white flex justify-center items-center w-10 h-10 rounded-full shadow focus:outline-none'
        style={{ zIndex: '39' }}
      >
        <svg
          viewBox='0 0 20 20'
          fill='currentColor'
          className='chevron-left w-6 h-6'
        >
          <path
            fillRule='evenodd'
            d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
            clipRule='evenodd'
          />
        </svg>
      </button>
    )}
  </div>
);

const NextBtn = (props: any) => {
  const { arrowType } = props;
  return (
    <div
      className='h-[35px] w-[35px] absolute top-1/2 transform -translate-y-1/2 z-10 right-[0px] cursor-pointer rightArrow'
      style={{ zIndex: '39' }}
    >
      {arrowType === 'Arrow1' && (
        <button
          name='Next'
          // onClick={clickHandler}
          className='bg-light-gray bg-opacity-90 flex justify-center items-center w-10 h-10 rounded-md shadow-md focus:outline-none'
        >
          <svg
            viewBox='0 0 20 20'
            fill='currentColor'
            className='chevron-right w-10 h-10'
          >
            <path
              fillRule='evenodd'
              d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      )}
      {arrowType === 'Arrow2' && (
        <button
          name='Next'
          // onClick={clickHandler}
          className='bg-white flex justify-center items-center w-10 h-10 rounded-full shadow focus:outline-none'
          style={{ zIndex: '39' }}
        >
          <svg
            viewBox='0 0 20 20'
            fill='currentColor'
            className='chevron-right w-6 h-6'
          >
            <path
              fillRule='evenodd'
              d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
};

interface ElementMeetTheTeamProps {
  data: TeamData;
  showHeadlineUp: boolean;
}

const ElementMeetTheTeam = ({
  data,
  showHeadlineUp,
}: ElementMeetTheTeamProps) => {
  const {
    dynamicarrowType,
    dynamicautoPlay,
    dynamicinfiniteLoop,
    dynamicshowArrow,
    dynamicshowIndicators,
    dynamiccarouselStatus,
  } = data;

  const isEnable = dynamiccarouselStatus?.value === 'On';
  const showArrows = isEnable && dynamicshowArrow?.value === 'On';
  const showIndicators = isEnable && dynamicshowIndicators?.value === 'On';

  const swiperConfig = {
    modules: [Navigation, Autoplay, Pagination],
    className: 'overflow-hidden swiper-initialized swiper-horizontal',
    navigation: {
      enabled: showArrows,
      nextEl: '.rightArrow',
      prevEl: '.leftArrow',
    },
    pagination: {
      enabled: showIndicators,
      clickable: true,
      el: '.paginationCL',
    },
    spaceBetween: 0,
    autoplay: isEnable && dynamicautoPlay?.value === 'On',
    loop: isEnable && dynamicinfiniteLoop?.value === 'On',
    breakpoints: {
      0: { slidesPerView: 1 },
      481: { slidesPerView: 2 },
      769: { slidesPerView: 3 },
      1025: {
        slidesPerView: Number(data?.scrolllogos_count_display?.value) || 4,
      },
    },
  };

  return (
    <div className='needClientUpdate'>
      {showHeadlineUp && <div className='' id='Headline1' />}
      <div
        className={`Meet-the-Team md:mb-[40px] mb-[20px] mx-[-8px] sm:mx-[-15px]${
          showHeadlineUp ? ' md:px-[50px] ' : ' '
        }relative`}
      >
        {showArrows && (
          <PrevBtn arrowType={dynamicarrowType.value || 'Arrow1'} />
        )}

        <Swiper {...swiperConfig}>
          {data?.dynamic?.value.map((member: TeamMember, index: number) => (
            <SwiperSlide key={index}>
              <div className='mx-[8px] sm:mx-[15px] text-center'>
                <div className='mb-[15px]'>
                  <img
                    src={member.Image}
                    alt={member.Headline}
                    className={`rounded-[${
                      data?.bordertype_border_radius?.value || 0
                    }px] overflow-hidden`}
                  />
                </div>
                <div
                  className={
                    data?.Headline_final_class?.value ||
                    'text-small-text font-bold mb-[5px]'
                  }
                >
                  {member.Headline}
                </div>
                <div
                  className={
                    data?.Description_final_class?.value ||
                    'text-extra-small-text font-light'
                  }
                >
                  {member.Description}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {showArrows && (
          <NextBtn arrowType={dynamicarrowType.value || 'Arrow1'} />
        )}
      </div>

      {showIndicators && <div className='paginationCL text-center' />}
      {!showHeadlineUp && <div className='' id='Headline1' />}
      <div
        className='text-medium-text font-light text-center max-w-[1070px] mx-auto'
        id='Description1'
      />
    </div>
  );
};

export default ElementMeetTheTeam;
