import ShareIcons from './components/SocialShare';
import { useTypedSelector_v2 } from '@hooks_v2/index';
import ElementImageGallery from '@templates/Home/components/ElementImageGallery';
import ElementScrollableLogos from '@templates/Home/components/ElementScrollableLogos';
import ElementTwoButtons from '@templates/Home/components/ElementTwoButtons';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import AtoZBrand from './components/AtoZBrand';
import BrandsTabbing from './components/BrandsTabbing';
import ElementAccordionDisplay from './components/ElementAccordionDisplay';
import ElementFullSlider from './components/ElementFullSlider';
import FeaturedProducts from './components/FeaturedProducts';

import AOS from 'aos';
import 'aos/dist/aos.css';

import 'aos/dist/aos.css';
import ImageVideoSlider from './components/ImageVideoSlider';
import CalendyCustomForm from './components/CalendyCustomForm';
import DIHomePage from './components/DIHomePage';
import ElementMeetTheTeam from './components/ElementMeetTheTeam';
import { updateSetProperties } from './components/Helper';

const Home2 = ({ html, pageData, slug }: any) => {
  const storeCode = useTypedSelector_v2((state) => state.store.code);

  const storeId = useTypedSelector_v2((state) => state.store.id);

  useEffect(() => {
    setTimeout(function () {
      AOS.init({
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        // offset: 120, // offset (in px) from the original trigger point
        // delay: 0, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: true, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger
      });
      AOS.refresh();
    }, 100);
    // AOS.init({startEvent: 'overlayLoaded'});
    // AOS.refresh();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      pageData.components?.map((res: { no: any; }, index: any) => {
        const el = document.querySelector(`#div${res.no}`);
        if (el) {
          const updateEl = el.querySelector('.needClientUpdate');
          if (updateEl) {
            updateSetProperties(res, document);
          }
        }
      });
    }, 100);
  }, [html]);

  return storeCode === 'DI' && (slug === '/' || slug === '') ? (
    <>
      <DIHomePage storeId={storeId}></DIHomePage>
    </>
  ) : (
    <div id='allContents'>
      <main>
        {html &&
          html.map((data: any, index: any) => {
            let html = <></>;
            if (data.attributes) {
              if (data.type === 1) {
                html = <ShareIcons />;
              } else if (data.type === 2) {
                html = (
                  <FeaturedProducts
                    {...data.data}
                    tabFeaturedPayload={pageData.featuredItems}
                  />
                );
              } else if (data.type === 3) {
                html = (
                  <ImageVideoSlider
                    {...data.data}
                    isCentered={data.attributes.node2.isImageCentered}
                  />
                );
              } else if (data.type === 4) {
                const componentValue = data.data;
                html = (
                  <>
                    {componentValue?.Title?.value && (
                      <div className='text-box-h2 mb-4' id='Title'>
                        {componentValue.Title.value ?? ''}
                      </div>
                    )}
                    <ul className='w-full'>
                      <ElementAccordionDisplay
                        selected_Values={componentValue}
                        acValues={componentValue?.FullAccordion?.value}
                      />
                    </ul>
                  </>
                );
              } else if (data.type === 5) {
                html = <ElementFullSlider {...data.data} />;
              } else if (data.type === 6) {
                html = <AtoZBrand />;
              } else if (data.type === 7) {
                html = <BrandsTabbing {...data.data} />;
              } else if (data.type === 8) {
                html = <CalendyCustomForm {...data.data} />;
              } else if (data.type === 9) {
                html = <ElementImageGallery {...data.data} />;
              } else if (data.type === 10) {
                html = <ElementScrollableLogos {...data.data} />;
              } else if (data.type === 11) {
                html = <ElementTwoButtons {...data.data} />;
              } else if (data.type === 12) {
                html = (
                  <ElementMeetTheTeam
                    showHeadlineUp={data.data.showHeadlineUp}
                    data={data.data.selValue}
                  />
                );
              }

              return (
                <div {...data.attributes.node1} key={index}>
                  <section {...data.attributes.node2}>
                    <div {...data.attributes.node3}>{html}</div>
                  </section>
                </div>
              );
            }
            return (
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: data,
                }}
              ></div>
            );
          })}
      </main>
    </div>
  );
};
export default Home2;
